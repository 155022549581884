import React, { useState, useRef, useEffect } from "react";
import logo from "../assets/logo.webp";
import NavBar from "../contains/NavBar";

const Header: React.FC = () => {
  const [flag, setFalg] = useState(false);
  const toggle = () => {
    let toggle: any = document.getElementById("toggle");
    toggle.classList.toggle("toggle");
    setFalg(!flag);
  };

  const videoRef1 = useRef<HTMLVideoElement>(null);
  const videoRef2 = useRef<HTMLVideoElement>(null);
  const videoRef3 = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement1 = videoRef1.current;
    if (videoElement1) {
      videoElement1.muted = true; // Mute the video to comply with autoplay restrictions
      videoElement1.play().catch((error) => {
        console.error("Video playback failed:", error);
      });
    }
    const videoElement2 = videoRef2.current;
    if (videoElement2) {
      videoElement2.muted = true; // Mute the video to comply with autoplay restrictions
      videoElement2.play().catch((error) => {
        console.error("Video playback failed:", error);
      });
    }
    const videoElement3 = videoRef3.current;
    if (videoElement3) {
      videoElement3.muted = true; // Mute the video to comply with autoplay restrictions
      videoElement3.play().catch((error) => {
        console.error("Video playback failed:", error);
      });
    }
  }, []);
  return (
    <div className="relative">
      <video ref={videoRef1} src="./1MOPE.mp4" className="w-full" loop></video>
      <video ref={videoRef2} src="./2MOPE.mp4" className="w-full" loop></video>
      <video
        ref={videoRef3}
        src="./3MOPE.mp4"
        className="w-full translate-y-[-1px]"
        loop
      ></video>
      <div className="absolute top-0 left-0 w-full h-full">
        {/* <img
          src={logo}
          alt="LOGO"
          className="absolute w-[5vw] max-[1024px]:w-[7vw] max-[768px]:w-[8vw] top-[30px] left-[30px] max-[768px]:left-[20px] max-[768px]:top-[20px] rounded-full border-4 border-white"
        /> */}
        {/* <div className="fixed top-[30px] right-[30px] p-3 max-[768px]:right-[15px] max-[768px]:top-[15px] z-50">
          <div
            className="cursor-pointer w-full bg-red z-10"
            id="toggle"
            onClick={toggle}
          >
            <div className="bg-white w-8 h-1 my-1 right-rotate transition duration-300"></div>
            <div className="bg-white w-8 h-1 my-1 middle_hidden transition duration-300"></div>
            <div className="bg-white w-8 h-1 my-1 left-rotate transition duration-300"></div>
          </div>
        </div> */}
        {/* <div className="text-center flex flex-col justify-center items-center">
          <h1 className="text-white mx-auto font-black text-[3vw] mt-[30px] MytextShadow animate-slidein">
            by chance do you think I am a god?
          </h1>
          <div className="mt-[5vw] text-[10vw] text-white MyBigShadow italic animate-slidein">
            MOPE
          </div>
        </div> */}

        {/* <p className="MytextShadow text-[3vw] text-white absolute left-1/2 -translate-x-1/2 bottom-[5%] w-full inline-block text-center">
          please check and lmk... 🧪 🐕
        </p> */}
        {/* <NavBar view={flag} /> */}
        <div className="absolute left-1/2 top-[1%] h-[7%] md:h-[5%] -translate-x-1/2 px-[1%] w-full flex flex-row items-center justify-between sm:gap-4">
          <a
            href="https://dexscreener.com/"
            target="_blank"
            rel="noreferrer"
            className="h-full"
          >
            <img
              src="./dex.png"
              alt=""
              className="h-full hover:scale-110 transition-all duration-200"
            />
          </a>
          <div className="flex flex-row gap-4 items-center justify-center h-full">
            <a
              href="https://x.com/MopeSolana"
              target="_blank"
              rel="noreferrer"
              className="h-full"
            >
              <img
                src="./x.png"
                alt=""
                className="h-full hover:scale-110 transition-all duration-200"
              />
            </a>
            <a
              href="https://t.me/MopeSPL"
              target="_blank"
              rel="noreferrer"
              className="h-full"
            >
              <img
                src="./tele.png"
                alt=""
                className="h-full hover:scale-110 transition-all duration-200"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
