import React from "react";
import logo from "../assets/logo.webp"


const Footer: React.FC = () => {
  return(
    <div className="bg-black flex justify-center sm:gap-3 lg:py-5 sm:py-5 py-2">
        <img src={logo} alt="mark" className="lg:w-[80px] sm:w-[60px] w-[30px]" />
        <div className="flex items-center text-white text-sm md:text-xl sm:text-base">
          &copy;2024 MOPE. ALL RIGHTS RESERVED.
        </div>
    </div>
  )
}

export default Footer;