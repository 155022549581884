import React from "react";
import Home from "./Layout/home";
import Movie from "./Layout/Movie";
import Detail from "./Layout/Detail";
import Footer from "./Layout/Footer";

const App: React.FC = () => {
  return(
    <div>
      <Home />
      {/* <Movie/> */}
      {/* <Detail /> */}
      <Footer />
    </div>
  )
}

export default App;